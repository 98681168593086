import React, { useState } from 'react';
import InputMask from 'react-input-mask';

function Form() {
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFullNameChange = (event) => {
        const input = event.target.value;
        const regex = /^[а-яА-ЯёЁ\s-]*$/;
        if (regex.test(input) || input === '') {
            setFullName(input);
        }
    };

    const validateFullName = (name) => {
        const parts = name.trim().split(/\s+/);
        return (parts.length === 2 || parts.length === 3) && parts.every((part) => /^[а-яА-ЯёЁ-]+$/.test(part));
    };

    const validatePhone = (phoneNumber) => {
        const digits = phoneNumber.replace(/\D/g, '');
        return digits.length === 11;
    };

    const getUrlParams = () => {
        const params = new URLSearchParams(window.location.search);
        return {
            utm: params.get('utm_campaign') || window.location.host,
            clickid: params.get('clickid'),
            creativeId: params.get('creative_id'),
            adCampaignId: params.get('ad_campaign_id'),
            imp_id: params.get('imp_id'),
            cost: params.get('cost'),
            payout: params.get('payout'),
        };
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!validateFullName(fullName)) {
            alert('Пожалуйста, введите ФИО в формате "Имя Фамилия" или "Фамилия Имя Отчество" используя только буквы.');
            setIsSubmitting(false);
            return;
        }

        if (!validatePhone(phone)) {
            alert('Пожалуйста, введите корректный номер телефона.');
            setIsSubmitting(false);
            return;
        }

        const siteName = window.location.host;
        const urlParams = getUrlParams();

        const data = {
            name: fullName,
            phone: phone,
            siteName: siteName,
            utm: urlParams.utm,
            clickid: urlParams.clickid,
            creativeId: urlParams.creativeId,
            adCampaignId: urlParams.adCampaignId,
            imp_id: urlParams.imp_id,
            payout: urlParams.payout,
            cost: urlParams.cost,
        };

        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key] === null ? '' : data[key]);
        });

        try {
            const response = await fetch('send.php', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                window.location.href = './thanks.html';
            } else {
                const errorText = await response.text();
                console.error('Ошибка сервера:', response.status, errorText);
                alert('Ошибка при отправке формы');
            }
        } catch (error) {
            console.error('Ошибка сети:', error);
            alert('Ошибка сети');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="formPage">
            <h1>Поздравляем! Вы получили доступ к платформе!</h1>
            <div className="formHolder">
                <h1>Заполните форму ниже, чтобы начать зарабатывать!</h1>
                <h2>Для граждан РФ старше 18 лет.</h2>

                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="fullname"
                        id="fullname"
                        placeholder="Фамилия Имя Отчество"
                        value={fullName}
                        onChange={handleFullNameChange}
                    />
                    <br />
                    <InputMask
                        mask="+7 (999) 999 99 99"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    >
                        {(inputProps) => (
                            <input
                                {...inputProps}
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="Телефон"
                            />
                        )}
                    </InputMask>

                    <button type="submit" className="yellowButton" disabled={isSubmitting}>
                        <span>Дальше</span>
                    </button>
                    <h3>Нажимая "Дальше", вы соглашаетесь с условиями</h3>
                </form>
            </div>
        </div>
    );
}

export default Form;
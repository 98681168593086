import React, { useState } from 'react';

function Quiz({ onComplete }) {
    const questions = [
        {
            question: "Имеете ли вы опыт в инвестировании?",
            options: [
                "Да, успешно инвестирую по сей день",
                "Нет, никогда прежде не инвестировал",
            ],
        },
        {
            question: "Ваш возраст?",
            options: [
                "Меньше 30",
                "30-50",
                "Старше 50",
            ],
        },
        // Добавьте больше вопросов по необходимости
    ];

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [fadeState, setFadeState] = useState('visible');

    const handleOptionChange = (event) => {
        const selectedOptionValue = event.target.value;

        setFadeState('fade-out');

        setTimeout(() => {
            setAnswers((prevAnswers) => {
                const newAnswers = [...prevAnswers, selectedOptionValue];
                const nextQuestionIndex = currentQuestionIndex + 1;

                if (nextQuestionIndex < questions.length) {
                    setCurrentQuestionIndex(nextQuestionIndex);
                    setFadeState('fade-in');

                    setTimeout(() => {
                        setFadeState('visible');
                    }, 500);
                } else {
                    // Викторина завершена
                    onComplete(newAnswers);
                }

                return newAnswers;
            });
        }, 500);
    };

    const progressPercentage = ((currentQuestionIndex) / questions.length) * 100;

    const currentQuestion = questions[currentQuestionIndex];

    if (!currentQuestion) {
        return null; // Или отобразить сообщение о завершении викторины
    }

    return (
        <div className={`quiz ${fadeState}`}>
            <h2>Узнайте, какая программа инвестирования подходит именно вам!</h2>
            <h1>{currentQuestion.question}</h1>

            <div className="ready">
                <div className="readyBar">
                    <div className="readyBarTitle">
                        <span>Готово:</span><span> {Math.round(progressPercentage)}%</span>
                    </div>
                    <div className="readyBarStatus">
                        <div className="readyBarIsFilled" style={{ width: `${progressPercentage}%` }}></div>
                    </div>
                </div>

                <img src="/gift.svg" alt="gift" />
            </div>

            <div className="irinaExpert">
                <img src="/irina.png" alt="Ирина" />
                <div className="irinaInfo">
                    <h1>Ирина</h1>
                    <h3>Аналитик-эксперт</h3>
                    <h2>Ответы на эти вопросы помогут нам подобрать оптимальную программу инвестирования</h2>
                </div>
            </div>

            <div className="inputHolder">
                {currentQuestion.options.map((option, index) => (
                    <label className="custom-radio" key={`q${currentQuestionIndex}-o${index}`}>
                        <input
                            type="radio"
                            name={`question-${currentQuestionIndex}`}
                            value={option}
                            onChange={handleOptionChange}
                        />
                        <span className="radio-circle"></span>
                        {option}
                    </label>
                ))}
            </div>
        </div>
    );
}

export default Quiz;

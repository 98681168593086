import React from 'react';

function StartPage({ onStart }) {
    return (
        <div className="startPage">
            <h1>Дарим до 50.000р на акции</h1>
            <h2>Пройдите опрос из 3-х вопросов и получите доступ к платформе.</h2>

            <button className="yellowButton" onClick={onStart}>
                <span>Получить акции</span>
            </button>

            <img src="/mainPic.webp" alt="picture" />
        </div>
    );
}

export default StartPage;

import './App.css';
import Header from './components/header';
import StartPage from './components/startPage';
import Quiz from './components/quiz';
import Form from './components/form';
import { useState } from "react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';

function App() {
    const [stage, setStage] = useState('start'); // Возможные значения: 'start', 'quiz', 'form'
    const [quizAnswers, setQuizAnswers] = useState([]);

    const handleStart = () => {
        setStage('quiz');
    };

    const handleQuizComplete = (answers) => {
        setQuizAnswers(answers);
        setStage('form');
    };

    return (
        <>
            <Header />

            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={stage}
                    timeout={500}
                    classNames="fade"
                    unmountOnExit
                >
                    <div>
                        {stage === 'start' && <StartPage onStart={handleStart} />}
                        {stage === 'quiz' && <Quiz onComplete={handleQuizComplete} />}
                        {stage === 'form' && <Form answers={quizAnswers} />}
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </>
    );
}

export default App;
